import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { ROLES } from "src/utils/setting"
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useForm, Controller } from 'react-hook-form';
import Upload from 'src/components/upload'
import Autocomplete from '@mui/material/Autocomplete';
import CalendarDialog from 'src/components/calendarDialog'
import { formatTimestamp, formatTimestampToLunar } from "src/utils/formatDateTime"
import CloseIcon from '@mui/icons-material/Close';
// import { Calendar, SelectCalendarLunar } from 'react-calendar-lunar';
import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";
import { Moon, Hemisphere } from "lunarphase-js";
import { Lunar, BlockLunarDate } from 'lunar-calendar-ts-vi';
// @mui
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  FormLabel
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import AlertDialog from 'src/components/dialog';
// sections
import { UserListHead, UserListToolbar } from 'src/sections/@dashboard/user';
// mock
import USERLIST from 'src/_mock/user';

// ----------------------------------------------------------------------
dayjs.extend(utc)
dayjs.extend(tz)
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 4),
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function AshesForm(props) {
  const navigate = useNavigate();

  let { id } = useParams();

  const [user, setUser] = useState(null);

  const [oldImageList, setOldImageList] = useState(null);
  const [oldRepresentImageList, setOldRepresentImageList] = useState(null);
  const [openDate, setOpenDate] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const { control, handleSubmit, setError, formState: { errors, isSubmitting }, setValue, watch, getValues, register } = useForm();
    

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };


  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  useEffect(() => {
    getDetailAshes(id);
  }, [id]);
  useEffect(() => {
    if(!id) getPagodaIndex()
}, []);
const getPagodaIndex = async() => {
  let pagodaIndex = await props.getPagodaIndex();
    if(pagodaIndex?.code === 1) {
      setValue("position",  `${pagodaIndex?.data?.prefix}${pagodaIndex?.data?.index || 0}`)
    }
    else setValue("position",  `0`)
  }
  const getDetailAshes = async (id) => {
    if (id) {
      let resDetailUser = await props.getDetailAshes({ id: id });
      if (resDetailUser?.code) {
        // setUser(resDetailUser.data)
        let data = resDetailUser.data
        Object.keys(data).forEach((name, index) => {
          setValue(name, Object.values(data)[index])
        });
        setOldImageList([
          ...data?.image || [],
        ])
        setOldRepresentImageList([
          ...data?.represent_image || []
        ])
      }
    }
  }
  const handleCreateUser = async (data) => {
    try {
      let dataUser = await checkUploadImage(data)
      let resultCreate = await props.createAshes(dataUser);
      if (resultCreate?.code) {
        navigate(`/dashboard/ashes`)
        // props.setAshState()
        toast("Tạo thành công")
      }
      else toast(resultCreate.message || resultCreate.error);
    } catch(error) {
      console.log(error)
      setError('apiError', {
        type: 'manual',
        message: 'An error occurred during the API call.',
      });
    }
     
  }
  const handleUpdateUser = async (data) => {
    try {
      let dataUser = await checkUploadImage(data)
      let resultUpdate = await props.updateAshes(dataUser);
      if (resultUpdate?.code) {
        // navigate(`/dashboard/ashes?page=${props.currentPage}&&limit=${props.currentLimit}`)
        toast("Cập nhật thành công")
      }
      else toast(resultUpdate.message || resultUpdate.error);
    } catch(error) {
      setError('apiError', {
        type: 'manual',
        message: 'An error occurred during the API call.',
      });
    }
  }

  const checkUploadImage = async (data) => {
    let removedImgs = []
    if (data?.image?.length) {
      let updatedImage = [...data?.image]
      for (let i = 0; i < data?.image.length; i++ ) {
        const imageEle = data?.image[i];
        if (data?.image[i]?.data) {
          const resUpload = await new Promise((resolve) => {
            resolve(props.uploadFile({
              files: [imageEle],
              path: "qltc"
            }))
          });  
          updatedImage[i] =  resUpload?.code === 1 ? resUpload?.data : null
        }
      }
      data.image = updatedImage;
      removedImgs = oldImageList?.filter(e => data?.image?.findIndex(imageEle => imageEle.url === e?.url) == -1)
    } else removedImgs = oldImageList
    if (data?.represent_image?.length) {
      let updatedImage = [...data?.represent_image]
      for (let i = 0; i < data?.represent_image.length; i++ ) {
        const imageEle = data?.represent_image[i];
        if (data?.represent_image[i]?.data) {
          const resUpload = await new Promise((resolve) => {
            resolve(props.uploadFile({
              files: [imageEle],
              path: "qltc"
            }))
          });  
          updatedImage[i] =  resUpload?.code === 1 ? resUpload?.data : null
        }
      }
      data.represent_image = updatedImage;
      removedImgs = oldRepresentImageList?.filter(e => data?.represent_image?.findIndex(imageEle => imageEle.url === e?.url) == -1)
    } else removedImgs = [...removedImgs || [], ...oldRepresentImageList || []]
    
    removedImgs = removedImgs?.filter((element) => element !== undefined && element !== null);
    if(removedImgs && removedImgs?.length) {
      await props.deleteFiles({
        image_url_array: removedImgs.map(e => e.url),
      });
    }
    return data;
  }
  const handleDeleteAsh = async () => {
    let resultDelete = await props.deleteAshes({ id: getValues()?.id });
    if (resultDelete?.code) {
      navigate(`/dashboard/ashes?page=${props.currentPage}&&limit=${props.currentLimit}`)
      toast("Xoá thành công")
    }
    else toast(resultDelete.message || resultDelete.error);
  }

  const formData = [
    {
      type: "section",
      label: props.t('thong_tin_nguoi_dai_dien'),
    },
    {
      type: "text",
      label: props.t('represent_name'),
      name: "represent_name",
      value: user?.represent_name,
      required: true,
    },
    {
      type: "text",
      label: props.t('represent_phone'),
      validateNumber: true,
      name: "represent_phone",
      value: user?.represent_phone,
      required: true,
    },
    {
      type: "text",
      label: props.t('represent_id'),
      name: "represent_id",
      value: user?.represent_id,
      required: true,
    }, 
    {
      type: "text",
      label: props.t('represent_address'),
      name: "represent_address",
      value: user?.represent_address,
      required: false,
    },
    {
      type: "image",
      label: props.t('represent_image'),
      name: "represent_image",
    },
    {
      type: "section",
      label: props.t('thong_tin_tro_cot_huong_linh'),
    },
    {
      type: "text",
      label: props.t('full_name'),
      name: "full_name",
      value: user?.full_name,
      required: true,
    },
    {
      type: "text",
      label: props.t('religious_name'),
      name: "religious_name",
      value: user?.religious_name,
      required: false,
    },
    {
      type: "text",
      label: props.t('gender'),
      name: "gender",
      value: user?.gender,
      required: true,
      type: "autocomplete"
    },
    {
      type: "text",
      // validateNumber: true,
      label: props.t('number_postion'),
      name: "position",
      value: user?.position,
      required: false,
      helperText: "Mã tro cốt + số được tự động tăng (thay đổi trong phần cài đặt)"
    },
    {
      type: "text",
      // validateNumber: true,
      label: props.t('Ghi chú'),
      name: "note",
      value: user?.note,
      required: false,
    },
    {
      type: "date",
      label: props.t('date_of_birth'),
      name: "date_of_birth",
      value: user?.date_of_birth,
      required: true,
    },
    {
      type: "date",
      label: props.t('deceased_date'),
      name: "deceased_date",
      value: user?.deceased_date,
      required: true,
    },
    {
      type: "text",
      validateNumber: true,
      label: props.t('age'),
      name: "age",
      value: user?.age,
      required: false,
    },
    {
      type: "date",
      label: props.t('sent_date_from'),
      name: "sent_date_from",
      value: user?.sent_date_to,
      required: false,
    },
    {
      type: "date",
      label: props.t('sent_date_to'),
      name: "sent_date_to",
      value: user?.sent_date_from,
      required: false,
    },
    {
      type: "date",
      label: props.t('latest_visit_date'),
      name: "latest_visit_date",
      value: user?.latest_visit_date,
      required: false,
      helperText: "Ngày viếng thăm tự động  được cập nhật sau khi in Sớ Ngọc"
    },
    {
      type: "image",
      label: props.t('ashes_image'),
      name: "image",
    }
  ]
  // Đổi ngày dương lịch ra số ngày Julius
  function  UniversalToJD( D,  M,  Y) {
    let JD;
    if (Y > 1582 || (Y == 1582 && M > 10) || (Y == 1582 && M == 10 && D > 14)) {
      JD = 367*Y - (7*(Y+((M+9)/12))/4) - (3*(((Y+(M-9)/7)/100)+1)/4) + (275*M/9)+D+1721028.5;
    } else {
      JD = 367*Y - (7*(Y+5001+((M-9)/7))/4) + (275*M/9)+D+1729776.5;
    }
    return JD;
  }  
  
  function tileContent({ date, view }) {
    // let d= 11;
    // let m= 1;
    // let y= 2024;
    // let lunar = Solar2Lunar(d,m,y)
    // let solar = Lunar2Solar(d,m,y)
    // console.log(`Đổi từ ngày Dương sang âm lịch` +  `${d}/${m}/${y}`)
    // console.log(`Là ngày` +  `${lunar[0]}/${lunar[1]}/${lunar[2]}`)
    // console.log(`Leap` +   lunar[3])

    let d= new Date(date).getDate();
    let m= new Date(date).getMonth() + 1;
    let y= new Date(date).getFullYear() + 1;
    const lunar2 = new Lunar();
    let lunarDate = lunar2.getBlockLunarDate(`${y}-${m}-${d}`);
    
    if (view === "month") {
      return (
        <small style={{ display: "block" }}> 
          {lunarDate.lunarDate === 1 ? `${lunarDate.lunarDate}/${lunarDate.lunarMonth}` : lunarDate.lunarDate}
        </small>
      );
    } 
    else if(view === "decade") 
    return (
      <small style={{ display: "block" }}> 
        {`(${lunarDate.lunarYearStr})`}
      </small>
    );
  } 
  const options = [
    {
      label: "Nam",
      value: 0
    },
    {
      label: "Nữ",
      value: 1
    },
  ]
  return (
    <Card>
      <AlertDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        title={props.t("confirm")}
        textBtnFirst={props.t("agree")}
        textBtnSecond={props.t("disagree")}
        content={`${props.t("are_you_sure_want_to_delete")} ${props.t("ashes")} ${getValues()?.full_name}`}
        handleActionFirst={() => {
          setOpenDialog(false);
        }}
        handleActionSecond={handleDeleteAsh}
      />
      <StyledContent>
        <Stack spacing={3}>
          {formData.map((e) => {
            if (e.hidden) return null;
            if (e.type === "section") return <h4 style={{ marginBottom: "16px" }}>{e.label}</h4>
            if (e.type === "date") return <><Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  gap: "10px" }}>
            <Controller
              // rules={{ required: e.required ? `${e.label} ${props.t('is_required')}` : '' }} // Add required rule
              name={e.name}
              control={control}
              render={({ field }) => (  
                <Box sx={{ display: 'flex' , flexDirection: "column" , width: "100%"}}>
                 <DesktopDatePicker
                  sx={{width: "100%"}}
                  label={`${e.label}` }
                  format="DD/MM/YYYY"
                  renderInput={(params) => 
                  <TextField {...params} />}
                  value={getValues()?.[e.name] ? dayjs(getValues()?.[e.name]).tz(dayjs.tz.guess()) : null}
                  onChange={(newValue) => {
                    setValue(e.name, dayjs.utc(newValue))
                  }}
                />
                {/* <FormLabel>{e.label}</FormLabel> */}
                <Box sx={{ display: 'flex', alignItems: "center"}}>
                (Ngày Âm lịch: {getValues()?.[e.name] ? formatTimestampToLunar(getValues()?.[e.name]): "..."})
                <Button
                color="primary"
                sx={{
                  maxWidth: "max-content",
                  marginTop: "0 !important"
                }}
                  variant="text"
                  // disabled={disabled} 
                  onClick={() => setOpenDate(e.name)}
                >
                  Xem Âm Lịch
                   
                </Button>
                </Box>
                
                </Box>
                
              )}
              
            />
             
            </Box> 
            {e.helperText && <FormHelperText sx={{
              mt: "6px !important"
            }}>{e.helperText}</FormHelperText>}
            </>
                 if(e.type == "autocomplete") return <Controller
                 name={e.name}
                 control={control}
                     // value={watch("pagoda_id")}
                     rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
                 render={({ field  }) => { return (
                   <Autocomplete
                    {...field}
                     options={[...options]}
                     getOptionLabel={(option) => option.label}
                     // defaultValue={null}
                     value={options.find(e=> e.value === watch("gender")) || null }
                     renderOption={(props, option) => {return <li {...props}>{`${option.label}`}</li>}}
                     onChange={(event, newValue) => {
                       setValue("gender", newValue?.value)
                             // onChangeValue({
                             //   target: {
                             //     name: "pagoda_id",
                             //     value: newValue?.id,
                             //   }
                             // });
                         }}
                    //  onInputChange={(event, value, reason) => {
                    //    if(value != "undefined" && value) {
                    //      props.getListPagoda({
                    //        keyword: value
                    //      })
                    //    }
                    //  }}
                     renderInput={(params) => <TextField {...params} 
                     error={Boolean(errors[e.name])}
                     helperText={errors[e.name]?.message} 
                     required={true}
                     label={props.t('Giới tính')}
                     />} 
                     /> 
                 )}}
                 
               /> 
            if (e.type === "image") return <Controller
              name={e.name}
              control={control}
              rules={{ required: e.required ? `${e.label} ${props.t('is_required')}` : '' }} // Add required rule
              render={({ field }) => (
                <Upload
                  label={e.label}
                  previewImgs={getValues()?.[e.name]?.length ? getValues()?.[e.name] : []}
                  uploadOnlyOneFile={false}
                  importUrl={false}
                  contentUploadButton={props.t("click_to_upload")}
                  uploadFile={(file) => setValue(e.name, file)}
                  deleteFile={(file) => setValue(e.name, file)}
                />
              )}
            />
            return <>
              <Controller
                type={e.type}
                name={e.name}
                control={control}
                rules={{
                  required: e.required ? `${e.label} ${props.t('is_required')}` : '',
                  // valueAsNumber: e.validateNumber ? true : false,
                  pattern: e.validateNumber ? {
                    value:/^[0-9]+$/,
                    message: props.t("please_enter_a_number"),
                  } : null,
                }} // Add required rule
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type={e.type}
                    required={e.required}
                    label={e.label}
                    error={Boolean(errors[e.name])} // Show error state if validation fails
                    helperText={errors[e.name]?.message} // Display error message

                    {...field}
                  />
                )}
              />
              {e.helperText && <FormHelperText sx={{
               mt: "6px !important"
             }}>{e.helperText}</FormHelperText>}
            </> 
          })}
           <CalendarDialog 
                handleClose={() => setOpenDate()}
                open={openDate ? true : false} 
                onChange={(newValue) => {
                  setValue(openDate, newValue ? dayjs.utc(newValue) : null)
                }} 
                value={getValues()?.[openDate] ? dayjs(getValues()?.[openDate]).tz(dayjs.tz.guess()) : null} 
                tileContent={tileContent}
                formatYear={(locale, date) => {console.log(locale); return locale.lunarYearStr}}
                />
        </Stack>
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="right" style={{ marginTop: '24px' }}>
          {id ? <Button variant="contained" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress style={{ color: 'white', width: 20, height: 20 }} /> : null} onClick={handleSubmit(handleUpdateUser)} >
            {props.t("update")}
          </Button>
            : <Button variant="contained" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress style={{ color: 'white', width: 20, height: 20 }} /> : <Iconify icon="material-symbols:add" />} onClick={handleSubmit(handleCreateUser)} >
              {props.t("create")}
            </Button>}
          {id ? <Button variant="contained" disabled={isSubmitting} color="error" startIcon={isSubmitting ? <CircularProgress style={{ color: 'white', width: 20, height: 20 }} /> : <Iconify icon="material-symbols:delete" />} onClick={() => {
            setOpenDialog(true)
          }}  >
            {props.t("delete")}
          </Button> : null}
        </Stack>
      </StyledContent>
    </Card>
  );
}

const mapState = (state) => ({
  listAshes: state.ashes.listAshes,
  currentPage: state.ashes.currentPage,
  currentLimit: state.ashes.currentLimit,
});
const mapDispatch = (dispatch) => ({
  getListAshes: dispatch.ashes.getListAshes,
  getDetailAshes: dispatch.ashes.getDetailAshes,
  createAshes: dispatch.ashes.createAshes,
  updateAshes: dispatch.ashes.updateAshes,
  deleteAshes: dispatch.ashes.deleteAshes,
  uploadFile: dispatch.upload.uploadFile,
  deleteFiles: dispatch.upload.deleteFiles,
  getPagodaIndex: dispatch.pagoda.getPagodaIndex,
});
export default connect(mapState, mapDispatch)(AshesForm)

