import *  as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import {formatTimestampToLunar} from "src/utils/formatDateTime"
import {
  TextField,
  Select,
  FormControl,
  InputLabel, 
  FormControlLabel,
  MenuItem
} from '@mui/material';
import Calendar from 'react-calendar';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));
export default function SendMessageDialog({
    open = false, 
    handleClose, 
    title, 
    content,
    handleActionFirst,
    handleActionSecond,
    textBtnFirst, 
    textBtnSecond, 
    onChange,
    name,
    value,
    handleSend,
    dataToken,
    itemRowSelected
}) {
  const { control, handleSubmit,  formState: { errors }, setValue, watch, getValues } = useForm();
  const formUser = [
    {
      type: "title",
      label: "Thông tin thân nhân tro cốt",
      name: "content",
      disable: true
    },
    {
      type: "select",
      label: "Mẫu tin nhắn",
      name: "template",
      options: [{
        name: 1,
        label: "Ngày giỗ",
        content: dataToken?.pagoda?.template_sms_1
      },{
        name: 2,
        label: "Ngày hết hạn gửi tro cốt",
        content: dataToken?.pagoda?.template_sms_2
      }]
    },
    {
      type: "text",
      label: "Nội dung thông báo",
      name: "description",
    },
  ] 

  if(new Date(value).toString() == 'Invalid Date') value = new Date()
  return (
    <Dialog
    open={open}
    fullWidth={true}
    maxWidth={"sm"}
    // onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
    Gửi thông báo 
    </DialogTitle>
    <DialogContent>
    <StyledContent>
        <Stack spacing={3}>
    {formUser.map((e) => {
            if (e.hidden) return null
            if(e.type === "title") return  <div>{` Họ và tên thân nhân: ${itemRowSelected && itemRowSelected.represent_name}. SĐT: ${itemRowSelected && itemRowSelected.represent_phone}
            `}</div>

            if(e.type === "select") return <Controller
            name={e.name}
            control={control}
            // rules={{ required: e.required ? `${e.label} ${props.t('is_required')}` : '' }} // Add required rule
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label" shrink={true}>{e.label}</InputLabel>
              <Select
              {...field}
                id="demo-simple-select-helper"
                // value={e.value}
                inputProps={watch(e.name) || null }
                value={watch(e.name) || null }
                // label={e.label} 
                onChange={(event) => {
                  setValue(e.name, event.target.value);
                  setValue("description", `${e.options[event.target.value - 1]?.content} ${itemRowSelected.full_name}, Pháp danh ${itemRowSelected.religious_name}, Ngày sinh ${itemRowSelected.date_of_birth ? formatTimestampToLunar(itemRowSelected.date_of_birth): ""} `)
                }}
              >
                {/* <MenuItem value="">
                  <em>Tự nhập</em>
                </MenuItem> */}
                {e.options.map(option => {
                  return <MenuItem value={option.name}>{option.label}</MenuItem>
                })}
                
              </Select>
            </FormControl>
            )}
          />
            return <>
            <Controller
                  name={e.name}
                  control={control}
                  // rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
                  render={({ field }) => (
                    <TextField 
                      InputLabelProps={{ shrink: true }}
                      type={e.type}
                      required={e.required}
                      label={e.label} 
                      multiline
                      rows={3}
                      maxRows={10}
                      InputProps={{
                        readOnly: e.disable ? true : false,
                      }}
                      error={Boolean(errors[e.name])} // Show error state if validation fails
                      helperText={errors[e.name]?.message} // Display error message
                      {...field} 
                    />
                  )}
                /> 
            </>  
          })} 
           </Stack>
      </StyledContent>
    </DialogContent>
    <DialogActions>
    <Button onClick={() => {
      handleClose()
      }} autoFocus  color="primary">
        {"Đóng"}
      </Button>
    <Button onClick={handleSubmit(handleSend)} autoFocus  color="error">
        {"Sao chép tin nhắn"}
      </Button>
      {/* <Button onClick={handleClose}>Chọn</Button> */}
    </DialogActions>
    {/* <DialogActions>
    <Button onClick={handleActionSecond} autoFocus  variant="contained" color="error">
        Delete
      </Button>
      <Button onClick={handleActionFirst}  variant="outlined"  color="secondary">Cancel</Button>
    </DialogActions> */}
  </Dialog>
  );
}
