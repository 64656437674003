import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { formatTimestamp } from "src/utils/formatDateTime"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import DocxEditor from 'src/components/form';
import SendMessageDialog from 'src/components/sendMessageDialog'
// sections
import { AshesListHead, AshesListToolbar } from 'src/sections/@dashboard/ashes';
import { ROLES, convertDays } from "src/utils/setting";

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function AshesPage(props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const moreThanSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const roleAdmin = props?.dataToken?.role_code === ROLES.ADMIN_ROLE
  const TABLE_HEAD = !moreThanSmall ? [
    { id: 'full_name', label: t('full_name'), alignRight: false },
    { id: 'religious_name', label: t('religious_name'), alignRight: false },
    { id: 'position', label: t('position'), alignRight: false },
    { id: 'gender', label: t('gender'), alignRight: false },
    { id: '' },
  ] :
    [
      { id: 'full_name', label: t('full_name'), alignRight: false },
      { id: 'religious_name', label: t('religious_name'), alignRight: false },
      { id: 'position', label: t('position'), alignRight: false },
      { id: 'gender', label: t('gender'), alignRight: false },
      { id: 'age', label: t('age'), alignRight: false },
      // { id: 'pagoda_name', label: t('user_pagoda_name'), alignRight: false, hideSortIcon: true, hidden: roleAdmin},
      // { id: 'deceased_date', label: t('deceased_date'), alignRight: false  },
      // { id: 'sent_date_from', label: t('sent_date_from'), alignRight: false, hidden: true },
      // { id: 'sent_date_to', label: t('sent_date_to'), alignRight: false, hidden: true },
      { id: 'represent_name', label: t('represent_name'), alignRight: false },
      // { id: 'due_date', label: t('Số ngày còn lại đến hạn gửi'), alignRight: false },
      // { id: 'death_anniversary', label: t('Số ngày còn lại đến ngày giỗ'), alignRight: false },
      // { id: 'represent_phone', label: t('represent_phone'), alignRight: false },
      // { id: 'represent_id', label: t('represent_id'), alignRight: false },
      { id: 'note', label: t('note'), alignRight: false },
      { id: '' },
    ];

  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const [itemRowSelected, setItemRowSelected] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('created');
  const [openSendMessage, handleOpenSendMessage] = useState(false);
   
  const [filterName, setFilterName] = useState('');
  const [filterPagoda, setFilterPagoda] = useState('');
  const [filterAshes, setFilterAshes] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleOpenMenu = (event, id, row) => {
    setOpen(event.currentTarget);
    setItemSelected(id)
    setItemRowSelected(row)
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setItemSelected(null);
    setItemRowSelected(null)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.listAshes.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    props.setAshState({ currentPage: newPage + 1 })
    navigate(`/dashboard/ashes?page=${newPage + 1}&limit=${parsed?.limit || rowsPerPage}`)
  };

  const handleChangeRowsPerPage = (event) => {
    props.setAshState({ currentLimit: parseInt(event.target.value, 10) })
    navigate(`/dashboard/ashes?page=${parsed?.page || page + 1}&limit=${parseInt(event.target.value, 10)}`)
  };

  const handleFilterByName = (event) => {
    setPage(0);
    navigate(`/dashboard/ashes?page=${1}&limit=${10}`)
    setFilterName(event.target.value);
  };

  const handleFilterByPagoda = (value) => {
    setFilterPagoda(value);
  };
  const onFilterAshesList = (value) => {
    setFilterAshes(value);
    navigate(`/dashboard/ashes?page=${1}&limit=${10}`)
  };

  const handleEditUser = () => {
    if (window) {
      window.open(`/dashboard/ashes/${itemSelected}`, '_blank')
    } else 
      navigate(`/dashboard/ashes/${itemSelected}`)
  }
  const handleSendMessageMessageUser = () => {
    // navigate(`/dashboard/ashes/${itemSelected}`)
  }
  const emptyRows = page > 0 ? rowsPerPage - props.listAshes.length : 0;

  // const filteredUsers = applySortFilter(props.listAshes, getComparator(order, orderBy), filterName);

  // const isNotFound = !filteredUsers.length && !!filterName;
  useEffect(() => {
    if (parsed?.limit) setRowsPerPage(parseInt(parsed?.limit, 10))
    if (parsed?.page) setPage(parseInt(parsed?.page) - 1)
  }, [parsed?.page, parsed?.limit]);
  useEffect(() => {
    let payloadObj = {
      page: parsed?.page || page + 1,
      limit: parsed?.limit || rowsPerPage,
      order: order,
      orderBy: orderBy,
      keyword: filterName,
    }
    if (filterAshes && filterAshes?.years) payloadObj.filterObject = {"latest_visit_time_year": `${filterAshes?.years}`}
    if (filterAshes && filterAshes?.dueDateCheck) payloadObj.filterObject = {...payloadObj.filterObject, "due_date": true}
    if (filterAshes && filterAshes?.distance) payloadObj.filterObject = {...payloadObj.filterObject, "distance_upcoming_death_anniversary": filterAshes?.distance}
    if (filterPagoda) payloadObj.filterObject = {...payloadObj.filterObject, "pagoda_id": `${filterPagoda}`}
    payloadObj.filterObject = JSON.stringify(payloadObj.filterObject)
    // if (filterAshes && filterAshes?.years) payloadObj.filterObject = `{"latest_visit_time_year": "${filterAshes?.years}"}`
    // if (filterAshes && filterAshes?.dueDateCheck) payloadObj.filterObject = `{"due_date": true}`
    // if (filterAshes && filterAshes?.dueDateCheck && filterAshes?.years) payloadObj.filterObject = `{"latest_visit_time_year": "${filterAshes?.years}", "due_date": true}`
    // if (filterPagoda) payloadObj.filterObject = `{"pagoda_id": "${filterPagoda}"}`
    // console.log(payloadObj)
    props.getListAshes(payloadObj);
  }, [rowsPerPage, page, order, orderBy, filterName, filterPagoda, filterAshes, parsed?.latest_visit_time_year]);
  const handleRedirectUserForm = () => {
    navigate("/dashboard/ashes/create")
  }
  const handleSendMessage = async(data) => {
    navigator.clipboard.writeText(data?.description)
    .then(() => {
      alert('Đã sao chép nội dung tin nhắn');
    })
    .catch(err => {
      console.error('Lỗi sao chép nội dung tin nhắn: ', err);
    });
    // let resSendMessage = await props.sendMessage({id: itemSelected, ...data})
    // handleOpenSendMessage(false)
    // if(resSendMessage.code) {
    //   toast(`Gửi thành công đến ${resSendMessage.data?.successCount} thiết bị (${resSendMessage.data?.failureCount} thiết bị không gửi được)`) 
    // } else toast(`Gửi không thành công. Mã lỗi: ${resSendMessage?.message}`)
  }
  return (
    <>
      <Helmet>
        <title> Tro cốt </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t("ashes")}
          </Typography>
          {roleAdmin && <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleRedirectUserForm}>
            {t("add_new")}
          </Button>
          }
        </Stack>

        <Card>
          <SendMessageDialog 
            open={openSendMessage} 
            handleClose={() => handleOpenSendMessage(false)}
            handleSend ={handleSendMessage}
            dataToken={props.dataToken}
            itemRowSelected={itemRowSelected}
          />
          <AshesListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            t={t}
            getListPagoda={props.getListPagoda}
            options={props.listPagoda}
            filterPagoda={filterPagoda}
            onFilterPagoda={handleFilterByPagoda}
            valueFilterPagoda={filterPagoda}
            dataToken={props.dataToken}
            onFilterAshesList={onFilterAshesList}
          />

          <Scrollbar>
            <TableContainer >
              <Table>
                <AshesListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={props.listAshes.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  hideCheckBox={true}
                />
                <TableBody>
                  {props.listAshes.map((row) => {
                    const { id, address, full_name, age, religious_name, position, note, gender, represent_id, represent_phone, deceased_date, sent_date_from, sent_date_to, represent_name, pagoda_name, pagoda_address, death_anniversary, due_date } = row;
                    const selectedUser = selected.indexOf(id) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell align="left">{full_name}</TableCell>
                        <TableCell align="left">{religious_name}</TableCell>
                        <TableCell align="left">{position}</TableCell>
                        <TableCell align="left">{gender === 0 ? "Nam":  gender === 1 ? "Nữ" : null}</TableCell>
                         
                        {moreThanSmall && <TableCell align="left">{age}</TableCell>}
                        {/* {(moreThanSmall && !roleAdmin) && <TableCell align="left">{pagoda_name}</TableCell>} */}
                        {/* <TableCell align="left">{formatTimestamp(deceased_date)}</TableCell>  */}
                        {/* {moreThanSmall && <TableCell align="left">{formatTimestamp(sent_date_from)}</TableCell>}
                        {/* {moreThanSmall && <TableCell align="left">{formatTimestamp(sent_date_from)}</TableCell>}
                        {moreThanSmall && <TableCell align="left">{formatTimestamp(sent_date_to)}</TableCell>} */}
                        {moreThanSmall && <TableCell align="left">{represent_name}</TableCell>}
                        {/* {moreThanSmall && <TableCell align="left">{convertDays(due_date)}</TableCell>}
                        {moreThanSmall && <TableCell align="left">{convertDays(death_anniversary)}</TableCell>} */}
                        {/* {moreThanSmall && <TableCell align="left">{represent_phone}</TableCell>}
                        {moreThanSmall && <TableCell align="left">{represent_id}</TableCell>} */}
                        {moreThanSmall && <TableCell align="left">{note}</TableCell>}
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 80 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {/* {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={props.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditUser}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Chỉnh sửa
        </MenuItem>

        <MenuItem  onClick={() => {handleOpenSendMessage(true)}} >
          <Iconify icon={'material-symbols-light:send'} sx={{ mr: 2 }} />
          Gửi tin
        </MenuItem>
      </Popover>
    </>
  );
}

const mapState = (state) => ({
  dataToken: state.auth.dataToken,
  listAshes: state.ashes.listAshes,
  total: state.ashes.total,
  listPagoda: state.pagoda.listPagoda,
});
const mapDispatch = (dispatch) => ({
  setAshState: dispatch.ashes.setState,
  getListAshes: dispatch.ashes.getListAshes,
  getListPagoda: dispatch.pagoda.getListPagoda,
  sendMessage: dispatch.ashes.sendMessage,
});
export default connect(mapState, mapDispatch)(AshesPage)

