import {  connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import {ROLES} from "src/utils/setting"
import {  toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Stack,
  Container,
  Typography,
  Button
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
// sections
import {  AshesForm } from 'src/sections/@dashboard/ashes';
 
function AshesFormPage(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  let { id } = useParams(); 
 const handleBack = () => {
      navigate(`/dashboard/ashes?page=${props.currentPage}&&limit=${props.currentLimit}`)
  } 
  const printForm = () => {
    navigate(`/dashboard/form/${id}`)
} 
  return (
    <>
    
      <Helmet>
        <title> Tro cốt </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" alignItems="center"  >
          <Iconify onClick={handleBack}  icon="openmoji:return" style={{width: "36px", cursor: "pointer"}} />
           <Typography variant="h4">{id ?  t("ashes_edit"):  t("ashes_create")}
          </Typography></Stack> 
          <Button onClick={() => printForm()} variant="contained">Xem và In sớ cầu siêu  / cầu an</Button>
        </Stack> 
       <AshesForm t={t} dataToken={props.dataToken}/>
      </Container>
     

    </>
  );
} 
const mapState = (state) => ({
  dataToken: state.auth.dataToken,
  currentPage: state.ashes.currentPage,
  currentLimit: state.ashes.currentLimit,
});
const mapDispatch = (dispatch) => ({

});
export default connect(mapState, mapDispatch)(AshesFormPage)

