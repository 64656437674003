
import React, { useState, useRef } from 'react';
import { Grid, Typography, Paper, Button } from '@mui/material';
import {formatTimestampToLunar} from "src/utils/formatDateTime"
import dayjs, { Dayjs } from 'dayjs';
import { toast } from 'react-toastify';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
const DotRow = () => {
  return (
    <div className="dot-row">
      {[...Array(2000)].map((_, index) => ("."))}
    </div>
  );
};
const SpaceRow = () => {
  return (
    <div className="space-row">
      {[...Array(2000)].map((_, index) => (" "))}
    </div>
  );
};
const FormPrint = (props) => {
  const socausieuRef = useRef();
  const socauanRef = useRef();
  const handlePrintSoCauSieu = useReactToPrint({
    content: () => socausieuRef.current,
    onAfterPrint: () => {
        // save 
        if(props.user) {
          props.updateAshes({
            latest_visit_date: dayjs.utc(),
            id: props.user?.id
          })
          toast("Đã cập nhật ngày viếng thăm gần nhất")
        }
      },
    onPrintError: (error) => {
      // error 
      console.log(error)
      toast(error.toString())
  },
    removeAfterPrint: true
  });
  const handlePrintSoCauAn = useReactToPrint({
    content: () => socauanRef.current,
    onAfterPrint: () => {
      // save 
      if(props.user) {
        props.updateAshes({
          latest_visit_date: dayjs.utc(),
          id: props.user?.id
        })
        toast("Đã cập nhật ngày viếng thăm gần nhất")
      }
    },
  onPrintError: (error) => {
    // error 
    console.log(error)
    toast(error.toString())
},
  removeAfterPrint: true
  });
  return (
    <div>
      <SoCauSieuToPrint ref={socausieuRef} data={props.user}/>
      <Grid container  direction="row"  justifyContent="center" spacing={2}>
        <Button onClick={() => handlePrintSoCauSieu()} variant="contained">In Sớ Cầu Siêu</Button>
      </Grid>
      <SoCauAnToPrint ref={socauanRef} data={props.user}/>
     
      <Grid container  direction="row"  justifyContent="center" spacing={2}>
      <Button onClick={() => handlePrintSoCauAn()} variant="contained">In Sớ Cầu An</Button>
      </Grid>   
    </div>
  );
};
const SoCauSieuToPrint = React.forwardRef((props, ref) => {
  const {data} = props;
  return (
    <div ref={ref}>
      <Paper style={{
        padding: "20px",
        // margin: "10px 10px",
        position: "relative"
      }}>
        <Typography variant="h3" gutterBottom align="center">
          SỚ CẦU SIÊU
        </Typography>
        <div className="container_image"><img src={"/assets/images/image_so_ngoc.png"}></img></div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="font-Playfair" variant="h6" gutterBottom  >
              Phục Dĩ
            </Typography>
            <div className="font-Roboto font-size-text">Tây phương Giáo chủ, cứu người sinh khỏi cảnh luân hồi,</div>
            <div className="font-Roboto font-size-text">Cực lạc Thế Tôn, tiếp kẻ thác lên đường trực vãng,</div>
            <div className="font-Roboto font-size-text">Cây Ngọc bẩy hàng cõi nước, chim Ca lăng gióng kệ Bồ đề,</div>
            <div className="font-Roboto font-size-text"> Mây vàng che phủ phương trời, đàn Khổng tước lắng kinh Bát thánh</div>
            <div className="font-Roboto font-size-text">Nghe Diệu nghĩa khởi lòng kính kín, lập chí tiến tu,</div>
            <div className="font-Roboto font-size-text">Hiểu Pháp âm, mộ đạo Từ bi, phát tâm bất thoái,</div>
            <div className="font-Roboto font-size-text">Người hiện thế tăng cường tuệ mệnh, ngũ trí viên minh,</div>
            <div className="font-Roboto font-size-text"> Khách mãn phần một gột sạch nghiệp căn, nhất tâm bất loạn.</div>
            <div className="font-Roboto font-size-text">Tây phương Giáo chủ, cứu người sinh khỏi cảnh luân hồi,</div>
            <Typography className="font-Playfair" variant="h6" gutterBottom  >
              Duyên nay
            </Typography>
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Tại nước Việt Nam, </div><DotRow /></div>
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Chúng con: </div><DotRow /></div>
            <DotRow />
            <DotRow />
            <DotRow />
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Hiện ở: </div><DotRow /></div>
            <DotRow />
            <div className="font-Roboto font-size-text">Chí kính đan thành, sám hối tụng kinh, cúng dường chư Phật, chư Bồ Tát, chư
              Thánh hiền chúng, nhất tâm khấn nguyện, cầu siêu độ cho chân linh:</div>
              {data?.full_name ? 
              <div>
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Hương Linh: {data.full_name}</div><DotRow /></div>
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Pháp danh: {data.religious_name}</div><DotRow /></div>
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Sinh: {data.date_of_birth && formatTimestampToLunar(data.date_of_birth)} </div><DotRow /></div>
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Mất:  {data.deceased_date && formatTimestampToLunar(data.deceased_date) }</div><DotRow /></div>
            <div className="font-Roboto"><div className="title_with_dots font_size_title">Hưởng thọ: {data.age} </div><DotRow /></div>

              </div> :
              <>
               <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
              </>
              }
            
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
          </Grid>
          <Grid item xs={6}>
            <Typography className="font-Playfair" variant="h6"   align="center">
              Thiết nghĩ
            </Typography>
            <div className="font-Roboto font-size-text">Chân linh: sinh trong ngũ trược, thường đam mê bao cảnh dục trần, thác xuống
              cửu tuyền, luống hãi sợ nhiều điều chướng ngại; Hoặc lỡ phạm tội khiên tam độc,
              đến giam cầm phẩn xí hoạch thang; Hoặc trót lầm uế chướng ngũ phong, phải chìm
              đắm đao sơn, kiếm thụ. Trượng kinh văn phúng tụng, tâm thức khai minh. Bằng
              nguyện lực gia trì, tinh thần giải ngộ, tuân theo lời Phật, một câu tụng niệm, thoát nạn
              ba đời.Vâng giữ phép thiền, hai chữ nam mô, giải oan lũy kiếp. </div>
            <Typography className="font-Playfair" variant="h6"   align="center">
              Nay nhân
            </Typography>
            <div className="font-Roboto font-size-text">Thiện hữu vân tề, kim bằng kết tập
              Đại chúng đồng thanh khấn nguyện, cầu chân vong chóng được siêu sinh
              Chư nhân nhất trí cẩn cầu, mong linh phách mau xuôi tịnh vực.  </div>
            <Typography className="font-Playfair" variant="h6"   align="center">
              Hiện tiền nhị chúng
            </Typography>
            <div className="font-Roboto font-size-text">Kinh văn một hội, phúng tụng chu viên</div>
            <div className="font-Roboto font-size-text">Văn biểu tấu mươi hàng kính mong chứng giám </div>

            <Typography className="font-Playfair" variant="h6"   align="center">
              Kính dâng
            </Typography>
            <div className="font-Roboto font-size-text">Nam mô Thập Phương Vô Lượng thường trụ Tam Bảo tác đại chứng minh </div>
            <div className="font-Roboto font-size-text">Nam mô Ta Bà Giáo Chủ Bổn Sư thích Ca Mâu Ni Phật tác đại chứng minh </div>
            <div className="font-Roboto font-size-text">Nam mô Tây Phương Cực Lạc Thế Giới Đại Từ Đại Bi Tiếp Dẫn Đạo Sư
              A Di Đà Phật tác đại chứng minh  </div>
            <div className="font-Roboto font-size-text">Nam mô Tam Thừa Đẳng Giác Chư Đại Bổ Tát Chư Hiền Thánh Tăng
              tác đại chứng minh </div>

            <Typography className="font-Playfair" variant="h6"   align="center">
              Ngửa trông
            </Typography>
            <div className="font-Roboto font-size-text">Di Đà giáo chủ, dùng tràng phan tiếp dẫn vong hồn, Địa Tạng đại sư, giơ tích trượng khai quang địa ngục, ngài Quán Âm nhủ lòng từ mẫn, đưa người sinh tới cõi chân như, đức Thế Chí ra sức biện bài, dẫn kẻ thác về nơi giải thoát, chốn Cực Lạc quê hương muôn thuở, ruổi bước thang mây, cảnh trần gian giả tạm nhất thời, quay đầu xe pháp. </div>
              <Typography className="font-Playfair" variant="h6"   align="center">
              Lại nguyện
            </Typography>
            <div className="font-Roboto font-size-text">Trên thập điện Diêm Vương hoan hỷ, gióng kệ khai thông. Dưới cửu u ngục tốt đê đầu nghe kinh giải kết. Chư tôn sứ giả, đón linh hồn tới trước toàn sen. Bản thổ Thành hoàng, đưa chân phách đến nơi bệ ngọc. Nước dương sái tịnh, chốn âm cung thưởng chữ an vui, câu chú hộ thân, nơi tử phủ nghe điều lợi lạc. Âm dương bất dị, đạo từ bi ngũ phúc bình tâm, sinh tử hà thù, lòng hỷ xả tam đa cát khánh. </div>
            <Typography className="font-Playfair" variant="h6"   align="center">
              Giải tấm lòng thành kính cẩn dâng sớ
            </Typography>
            <div className="font-Roboto font-size-text"> Sớ tuyên tại:.........................., ngày.............tháng.............năm........ </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
});
const SoCauAnToPrint = React.forwardRef((props, ref) => {
  const {data} = props;
  return (
    <div ref={ref}> 
      <Paper style={{
        padding: "20px",
        // margin: "10px 10px",
        position: "relative"
      }}>
        <Typography variant="h3" gutterBottom align="center">
          SỚ CẦU AN
        </Typography>
        <Typography className="font-Playfair" variant="h5" gutterBottom align="center">
          Phục Dĩ
        </Typography>
        <div className="container_image"><img src={"/assets/images/image_so_ngoc.png"}></img></div>
        <Typography className="font-Playfair" variant="h6" gutterBottom align="center">
          Phước Thọ Khang An lòng người ước nguyện, Tai ương hạn ách ai cũng mong qua

        </Typography>
        <Typography className="font-Playfair" variant="h6" gutterBottom align="center">Một niệm chi thành, muôn thiên hỗ trợ
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="font-Roboto font_size_title"><div className="title_with_dots">Cầu an tại, </div><DotRow /></div>
            <div className="font-Roboto font_size_title"><div className="title_with_dots">Nay chúng con: </div><DotRow /></div>
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <DotRow />
            <div className="font-Roboto"><div className="title_with_dots ">Hiện ở: </div><DotRow /></div>
            <DotRow />
            <DotRow />
          </Grid>
          <Grid item xs={6}>
            <Typography className="font-Playfair" variant="h6" gutterBottom align="center">
              Nhất tâm cầu nguyện
            </Typography>
            <div className="font-Roboto font-size-text">Chư Phật Thánh Hiền, từ bi chiếu giám, chúng con thiết nghĩ sống trong trần thế,
              nghiệp chướng nặng nề, nào ách nạn quẩn quanh trước mặt, nào yếu đau luẩn quẩn
              bên mình. Ngày tháng lo âu, sớm hôm e ngại. Nếu không nhờ sức thần thông
              gia hộ thì mong đâu được vạn sự bình an. </div>
            <div className="font-Roboto font-size-text"><div className="float-left">Vậy hôm nay nhân ngày: </div><DotRow /></div>
            <div className="font-Roboto font-size-text">Chúng con chân thành sám hối, dâng bản sớ văn, đồng thời nghi lễ hương hoa kính dâng Tam Bảo.</div>
            <br />
            <div className="font-Roboto font-size-text">Nam mô Ta Bà Giáo Chủ Bổn Sư thích Ca Mâu Ni Phật </div>
            <div className="font-Roboto font-size-text">Nam mô Đông Phương Giáo Chủ Dược Sư Lưu Ly Quang Vương Phật </div>
            <div className="font-Roboto font-size-text"> Nam mô Đại Từ Bi Cứu Khổ Cứu Nạn Linh Cảm Quan Thế Âm Bồ Tát</div>
            <div className="font-Roboto font-size-text"> Nam mô Hộ Pháp thiện Thần Nhất Thiết Hiền Thánh</div>
            <br />
            <Typography className="font-Playfair" variant="h6" gutterBottom align="center">
              Muôn trông
            </Typography>
            <div className="font-Roboto font-size-text"> Tam Bảo phù trì, vạn linh gia hộ cho đệ tử chúng con nghiệp chướng, phiền
              não chướng, mọi chướng tiêu trừ, ác duyên, oan trái duyên, chư duyên hòa hợp.
              Điều lành đưa tới, việc xấu qua đi. Ách nạn vụt tan, không yếu, không đau, mau bình phục.
              Cây ngũ phúc ngày thêm xanh tốt, cửa Tam Đa rợp bóng an lành.</div>
            <SpaceRow />
            <div style={{
              marginLeft: "20%"
            }}>
              <div className="font-Roboto font-size-text">Giải tấm lòng thành, cúi xin chứng giám </div>
              <div className="font-Playfair font-size-text">Vận trời, ngày....... tháng.......năm........ </div>
              <div className="font-Roboto font-size-text">Đệ tử chúng con thành tâm dâng sớ tấu </div>
            </div>

          </Grid>
        </Grid>
      </Paper>
    </div>
  );
});
export default FormPrint;